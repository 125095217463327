<template>
  <ValidationObserver ref="proposalFormInitPage">
    <div class="card card-custom">
      <div class="card-body container">

        <b-card class="mb-5 mt-3" body-class="px-7 py-5">
          <b-alert v-if="errorAlert" variant="warning" show>
            <div class="text-dark" v-html="errorAlert"></div>
          </b-alert>

          <div v-if="brand !== 'FUSE'" class="form-group row">
            <div class="col-6">
              <span class="font-weight-bold font-size-h4">
                Is the person initiating this proposal form a Broker?<span class="text-danger">*</span>
              </span>
              <b-form-group class="mt-2">
                <ValidationProvider rules="required" name="personCompletingForm" v-slot="{ errors }">
                  <b-form-radio-group type="radio" v-model="userInfo.personCompletingForm">
                    <b-form-radio type="radio" value="broker">Yes</b-form-radio>
                    <b-form-radio type="radio" value="client">No</b-form-radio>
                  </b-form-radio-group>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>

          <div v-if="brand === 'RENTSURE' && userInfo.personCompletingForm === 'broker'">
            <strong>
              You are requested to complete the <a href="https://crinsurance.com.au">Car Rental Insurance</a> Proposal Form. Click on the button below to access the Proposal Form.<br/><br/>
              <a class="btn btn-primary" target="_blank" href="https://link.crinsurance.com.au/start-proposal-form">Car Rental Insurance Proposal Form</a>
            </strong>
          </div>

          <div v-if="brand === 'CRI' && userInfo.personCompletingForm === 'client'">
            <strong>
              You are requested to complete the <a href="https://rentsure.com.au">Rentsure</a> Proposal Form. Click on the button below to access the Proposal Form.<br/><br/>
              <a class="btn btn-primary" target="_blank" href="https://link.rentsure.com.au/start-proposal-form">Rentsure Proposal Form</a>
            </strong>
          </div>

          <div v-if="isValidBrandForm">
            <span class="font-weight-bold font-size-lg">Please enter your details below to receive the proposal form link.</span>

            <div class="form-group row">
              <div class="col-6">
                <label class="col-form-label text-left">
                  First Name<span class="text-danger">*</span>
                </label>
                <ValidationProvider rules="required|max:255" name="firstName" v-slot="{ errors }">
                  <input type="text" class="form-control" v-model.trim="userInfo.firstName" maxlength="255"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label text-left">
                  Last Name<span class="text-danger">*</span>
                </label>
                <ValidationProvider rules="required|max:255" name="lastName" v-slot="{ errors }">
                  <input type="text" class="form-control" v-model.trim="userInfo.lastName" maxlength="255"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-6">
                <label class="col-form-label text-left">
                  Email<span class="text-danger">*</span>
                </label>
                <ValidationProvider rules="required|email|max:100" name="email" v-slot="{ errors }">
                  <input type="text" class="form-control" v-model.trim="userInfo.email" maxlength="100"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <vue-recaptcha :sitekey="'6LfROSYfAAAAANtO-fIhOZURzKagc6XgofmbEPIt'"
                           @verify="captchaVerified=true" @expired="captchaVerified=false"
            ></vue-recaptcha>

            <div class="d-flex justify-content-between border-top pt-10">
              <div class="mr-2">
                <button class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        @click.prevent="submitForm" :disabled="!isUserInfoValid || !captchaVerified">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import _ from "lodash";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import { VueRecaptcha } from 'vue-recaptcha';
import BrandService from "@/core/services/brand.service";

export default {
  name: "MotorProposalFormInit",

  components: { VueRecaptcha },

  data() {
    return {
      serverUrl: null,
      brand: null,
      captchaVerified: false,
      errorAlert: null,

      userInfo: {
        personCompletingForm: null,
        firstName: null,
        lastName: null,
        email: null
      }
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.brand) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.brand = _.toUpper(queryParams.brand);
    this.userInfo.brand = this.brand;

    // Set Title and Brand
    this.$store.commit("setWizardTitle", "Motor Proposal Form");

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }

    // Set UI Brand
    BrandService.setUIBrand(this.brand);

    this.stopPageLoading();
  },

  computed: {
    isValidBrandForm: function() {
      const pcf = this.userInfo.personCompletingForm;
      if(this.brand === 'RENTSURE') {
        return pcf === 'client';
      }
      else if(this.brand === 'CRI') {
        return pcf === 'broker';
      }
      else if(this.brand === 'FUSE') {
        return true;
      }
      return false;
    },

    isUserInfoValid: function () {
      let isValid = !!this.userInfo.firstName && !!this.userInfo.lastName && !!this.userInfo.email;
      if(this.brand !== 'FUSE') {
        isValid = isValid && !!this.userInfo.personCompletingForm;
      }
      return isValid;
    }
  },

  methods: {

    submitForm: async function () {
      if(!this.isValidBrandForm || !this.isUserInfoValid) {
        return;
      }

      const isFormValid = await this.$refs.proposalFormInitPage.validate();
      if(!isFormValid) {
        return;
      }

      this.startPageLoading();

      this.$apiService.post(`${this.serverUrl}/mpf/init-form`, this.userInfo)
        .then(() => {
          this.stopPageLoading();

          Swal.fire({
            titleText: "Thank You",
            html: `<p>You will receive a link in your email to access the Motor Proposal Form.</p>
                   <br/><span class="font-weight-bold">Please close this browser window.</span>`,
            icon: "success",
            width: "40rem",
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: false,
            showCancelButton: false,
            showCloseButton: false
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      console.log(errorObj);
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
